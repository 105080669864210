import {
  Badge,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { FaEllipsisV } from "react-icons/fa";
import Swal from "sweetalert2";
import { API, graphqlOperation } from "aws-amplify";
import { deleteEnvivos } from "graphql/mutations"; // Asegúrate de que esta mutación esté definida

function TablesTableRowEnvivos(props) {
  const {
    id,
    title,
    subtitle,
    timestamp,
    platform,
    url,
    visibility,
    coverImage,
    getAllEnvivos,
    isLast,
  } = props;

  const borderColor = useColorModeValue("gray.200", "gray.600");

  async function deleteEnvivoFn() {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta operación no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const envivoDetails = { id: id };
        try {
          await API.graphql(
            graphqlOperation(deleteEnvivos, { input: envivoDetails }),
          );
          Swal.fire("Eliminado!", "El envivo ha sido eliminado.", "success");
          getAllEnvivos();
        } catch (error) {
          Swal.fire("Error", "El envivo no ha sido eliminado.", "error");
          console.log(error);
        }
      }
    });
  }

  return (
    <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" fontWeight="bold">
          {title}
        </Text>
        <Text fontSize="sm" color="gray.400">
          {subtitle}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{timestamp}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Badge colorScheme="blue">{platform}</Badge>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{url}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{visibility}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{coverImage}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Menu>
          <MenuButton>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={deleteEnvivoFn}>Eliminar</MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TablesTableRowEnvivos;
