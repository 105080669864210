// import
import React from "react";
import Dashboard from "views/Dashboard/Dashboard.js";
import Tables from "views/Dashboard/Tables.js";

import "antd/dist/antd.css";
import "./style.css";

import { HomeIcon } from "components/Icons/Icons";

import TablesSignals from "views/Dashboard/TablesSignals";
import Manual from "views/Dashboard/Manual";

import { HiBookOpen, HiCalendar, HiUsers } from "react-icons/hi";
import { FiUserX } from "react-icons/fi";
import { AiTwotoneNotification } from "react-icons/ai";
import {
  FaPlane,
  FaPlaneArrival,
  FaPlaneDeparture,
  FaTools,
  FaUser,
} from "react-icons/fa";
import {
  BiBarChartAlt,
  BiBarChartSquare,
  BiChart,
  BiPlayCircle,
  BiSolidVideoRecording,
  BiWorld,
} from "react-icons/bi";
import { BiNews } from "react-icons/bi";
import TablesNews from "views/Dashboard/TablesNews";
import TablesNewsPrincipales from "views/Dashboard/TablesNewsPrincipales";
import TablesUserDeleted from "views/Dashboard/TablesUserDeleted";
import TablesEnvivos from "views/Dashboard/TablesEnvivos";
import TablesTravelUsers from "views/Dashboard/TablesTravelUsers";
import TablesTravelActivations from "views/Dashboard/TablesTravelActivations";
import TablesTravelSearchActivations from "views/Dashboard/TablesTravelSearchActivations";
import TablesTravelSearchTravels from "views/Dashboard/TablesTravelSearchTravels";
import { Card } from "@chakra-ui/react";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    name: "USUARIOS",
    category: "robots",
    state: "pageCollapse",
    views: [
      {
        path: "/users",
        name: "Todos",
        icon: <HiUsers color="inherit" />,
        component: Tables,
        layout: "/admin",
      },
      /*  {
        path: "/users-deleted",
        name: "Eliminados",
        icon: <FiUserX color="inherit" />,
        component: TablesUserDeleted,
        layout: "/admin",
      }, */
    ],
  },
  {
    name: "SEÑALES",
    category: "robots",
    state: "pageCollapse",
    views: [
      {
        path: "/all-signals",
        name: "Todas",
        icon: <AiTwotoneNotification color="inherit" />,
        component: TablesSignals,
        layout: "/admin",
      },
      {
        path: "/manual-mode",
        name: "Crear",
        icon: <FaTools color="inherit" />,
        component: Manual,
        layout: "/admin",
      },
    ],
  },
  {
    name: "VIAJES",
    category: "robots",
    state: "pageCollapse",
    views: [
      {
        path: "/all-travel-activations",
        name: "Activar Usuario",
        icon: <FaPlane color="inherit" />,
        component: TablesTravelActivations,
        layout: "/admin",
      },
      {
        path: "/all-travel-users",
        name: "Buscar Usuarios",
        icon: <FaUser color="inherit" />,
        component: TablesTravelUsers,
        layout: "/admin",
      } /* 
      {
        path: "/search-travel-activations",
        name: "Buscar Activaciones",
        icon: <HiCalendar color="inherit" />,
        component: TablesTravelSearchActivations,
        layout: "/admin",
      },
      {
        path: "/search-travel-travels",
        name: "Buscar Viajes",
        icon: <FaPlaneDeparture color="inherit" />,
        component: TablesTravelSearchTravels,
        layout: "/admin",
      }, */,
    ],
  },
  {
    name: "NOTICIAS",
    category: "robots",
    state: "pageCollapse",
    views: [
      {
        path: "/all-news",
        name: "Todas",
        icon: <BiNews color="inherit" />,
        component: TablesNews,
        layout: "/admin",
      },
    ],
  },
  {
    name: "EN VIVOS",
    category: "robots",
    state: "pageCollapse",
    views: [
      {
        path: "/all-lives",
        name: "Todos",
        icon: <BiSolidVideoRecording color="inherit" />,
        component: TablesEnvivos,
        layout: "/admin",
      },
    ],
  },
  {
    name: "SOCIAL TRADING",
    category: "robots",
    state: "pageCollapse",
    views: [
      {
        path: "/all-lives",
        name: "Estrategias",
        icon: <BiBarChartAlt color="inherit" />,
        component: TablesEnvivos,
        layout: "/admin",
      },
    ],
  },
  {
    name: "CUENTAS AUDITADAS",
    category: "robots",
    state: "pageCollapse",
    views: [
      {
        path: "/all-lives",
        name: "Todas",
        icon: <BiBarChartSquare color="inherit" />,
        component: TablesEnvivos,
        layout: "/admin",
      },
    ],
  },
  {
    name: "TUTORIALES",
    category: "robots",
    state: "pageCollapse",
    views: [
      {
        path: "/all-tutorials",
        name: "Todos",
        icon: <BiPlayCircle color="inherit" />,
        component: Card,
        layout: "/admin",
      },
    ],
  },
];
export default dashRoutes;
